import { defineStore } from 'pinia'
import paperTaskMissionApi from "@/api/paperTaskMission";
import paperMissionsApi from "@/api/paperMissions";
type paperMissions = {
  subjectList:Array<object>,
  currentSubjectItem:object,
  klassList:Array<object>,
  currentKlassItem:object,
  paperSheet:Array<object>,
}

export default defineStore({
  id: 'paperMissions',
  state: (): paperMissions => ({
    subjectList:[],
    currentSubjectItem:{},
    klassList:[],
    currentKlassItem:{},
    paperSheet:[]
  }),
  actions: {
    async apiPaperMissionsClassesKlassIdSubjects (params){
      return paperMissionsApi.classes_klassId_subjects(params).then(resp=>{
        this.subjectList=resp;
        if (this.currentSubjectItem.id == null) {
          this.currentSubjectItem=this.subjectList[0];
        }
        console.log('apiMissionsRoleList',resp)
      })
    },
    async apiPaperTaskMissionPaperSheet() {
      return paperTaskMissionApi.paper_sheet({}).then((res: any) => {
        this.paperSheet = res;
      });
    },
    async RESET_STATE() {
      this.$reset()
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'paperMissions',
        storage: localStorage
      }
    ]
  }
})