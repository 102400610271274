import { defineStore } from 'pinia'

type account = {
  token: string
  refreshToken: string
  userId: string
  name: string
  avatar: string
  expire: number
  routes: Array<object>
}

export default defineStore({
  id: 'account',
  state: (): account => ({
    refreshToken: "",
    token: '',
    name: '',
    avatar: '',
    userId: '',
    expire: 0,
    routes: [],
  }),
  actions: {
    async RESET_STATE() {
      this.$reset()
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'account',
        storage: localStorage,
        paths: ['token', 'refreshToken', "avatar", "name", "userId", "expire"]
      }
    ]
  }
})