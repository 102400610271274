import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router/index'
import userStore from '@/stores/accountStore'
import { encode } from 'js-base64'

const statusMapping = new Map<number, string>()
statusMapping.set(400, '请求错误')
statusMapping.set(401, '未授权，请登录')
statusMapping.set(403, '拒绝访问')
statusMapping.set(404, '资源找不到')
statusMapping.set(405, '请求方法不正确!')
statusMapping.set(408, '请求超时')
statusMapping.set(500, '服务器内部错误')
statusMapping.set(501, '服务未实现')
statusMapping.set(502, '网关错误')
statusMapping.set(503, '服务不可用')
statusMapping.set(504, '网关超时')
statusMapping.set(505, 'HTTP版本不受支持')

const http = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 1000 * 60, //1分钟
  headers: {
    Authorization: `Basic ${encode(
      `${import.meta.env.VITE_APP_CLIENT_ID}:${import.meta.env.VITE_APP_CLIENT_SECRET}`
    )}`,
    tenant: 'MDAwMA==',
  }
})

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = 'Bearer ' + userStore().token
    if (token) {
      config.headers = {
        ...config.headers,
        token: token
      }
    }
    return config
  },
  (error: any) => {
    error.data = {}
    error.data.message = '服务器异常，请联系管理员！'
    return error
  }
)

http.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res.status === 200) {
      if (res.config.isCode) {
        return res.data
      } else {
        if (res.data.isSuccess) {
          return res.data.data
        } else {
          ElMessage.error(res.data.msg)
          return Promise.reject(res)
        }
      }
    } else if (!res.data.isSuccess) {
      ElMessage.error(res.data.msg)
      return Promise.reject(res)
    } else {
      ElMessage.error(res.data.errorMsg || '服务器出错!')
      return Promise.reject(res.data)
    }
  },
  error => {
    let message = '未知错误，请联系管理员！'
    if (error && error.response.status === 401) {
      ElMessageBox.confirm('您的登录已过期,确认重新登录', '登录过期', {
        confirmButtonText: '确定',
        showCancelButton: false,
      })
        .then(() => {
          userStore().RESET_STATE()
          router
            .replace({
              path: '/login',
              replace: true,
              query: {
                redirect: router.currentRoute.value.fullPath
              }
            })
            .finally(() => location.reload())
        })
      return Promise.reject(error)
    } else if (error && error.response.status === 400) {
      ElMessage.error(error.response.data.msg)
      return Promise.reject(error)
    } else if (error && error.response) {
      message = error.response.data.errorMsg || statusMapping.get(error.response.status) as string
    } else {
      if (error.message.indexOf('timeout') > -1) {
        message = '网络超时，请连接WIFI试试！'
      } else if (error.message.indexOf('Network') > -1) {
        message = '断网了，请打开网络连接试试！'
      }
    }
    ElMessage.error(message)
    return Promise.reject(error)
  }
)

export default http
