import { defineStore } from "pinia";
import baseApi from "@/api/base";

export default defineStore({
  id: "attribute",
  state: () => ({
    attributes: {
      TERM: [],
      GRADE: [],
      SUBJECT: [],
      EDITION: [],
      VOLUME: [],
      QUESTION_DIFFICULTY: [],
      QUESTION_TYPE: [],
      QUESTION_SOURCE: [],
      QUESTION_STATUS: [],
      PARTICULAR_YEAR: [],
    },
    questinTypes: {
      id: "",
      list: [],
    },
  }),
  actions: {
    async getBasicAttribute() {
      for (const key in this.attributes) {
        this.attributes[key].length ||
          baseApi.getBasicAttribute(key).then((res) => {
            this.attributes[key] = res;
          });
      }
    },
    async upateAttribute(key) {
      baseApi.getBasicAttribute(key).then((res) => {
        this.attributes[key] = res;
      });
    },
    async RESET_STATE() {
      this.$reset();
    },
    getSubjectQuesionTypes(subjectId = '') {
      const params = {
        subject: subjectId || this.questinTypes.id,
        page: 1,
        size: 999,
      }
      this.questinTypes.id = params.subject
      baseApi.getQuestionType(params).then((res) => {
        console.log(res,'这里');
        this.questinTypes.list = res.records
      });
    },
  },
  persist: {
    enabled: true,
  },
});
