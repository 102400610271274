
<script>
export default {
  name: "router-wrap",
};
</script>
<template>
  <router-view v-slot="{Component, route}">
    
    <keep-alive>
      <component :is="Component" v-if="route.meta.isKeepAlive" :key="Component"/>
    </keep-alive>
    <component :is="Component" v-if="!route.meta.isKeepAlive" :key="Component"/>
  </router-view>
</template>
<style scoped>

</style>
