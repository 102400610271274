const getTimeStare = (() => {
    // 获取当前时间
    let timeNow = new Date();
    // 获取当前小时
    let hours = timeNow.getHours();
    // 设置默认文字
    let text = ``;
    // 判断当前时间段
    if (hours >= 0 && hours <= 9) {
        text = `早上好`;
    }
    else if (hours > 9 && hours <= 12) {
        text = `上午好`;
    }
    else if (hours > 12 && hours <= 13) {
        text = `中午好`;
    } else if (hours > 13 && hours <= 18) {
        text = `下午好`;
    } else if (hours > 18 && hours <= 24) {
        text = `晚上好`;
    }
    // 返回当前时间段对应的状态
    return text;
})
export { getTimeStare }