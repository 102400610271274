import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import loginApi from "@/api/login";
import accountStore from "@/stores/accountStore";
import Layout from "@/layouts/index.vue";
import RouterWrap from "@/components/RouterWrap.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/commentsHomework",
    name: "commentsHomework",
    component: () => import("@/views/commentsHomework.vue"),
    meta: {
      title: "作业投影",
    },
  },

  {
    path: "/canvas",
    name: "canvas",
    component: () => import("@/views/canvas.vue"),
    meta: {
      title: "作业投影",
    },
  },
  // 题库页面
  {
    path: "/",
    component: Layout,
    children: [
      //{ path: '/audit/testquestion/detail', name: 'auditQuestionDetail', component: () => import('@/views/audit/testquestion/detail.vue'), meta: { navOnly: true } },
      //{ path: '/audit/question/detaill', name: 'questionDetails', component: () => import('@/views/audit/question/detaill.vue'), meta: { navOnly: true } },
      //{ path: '/audit/wrong/detaill', name: 'wrongs', component: () => import('@/views/audit/wrong/detaill.vue'), meta: { navOnly: true } },
      //{ path: '/question-manager/question-edit', name: 'questionOperation', component: () => import('@/views/question-manager/edit-question/edit-question.vue'), meta: { navOnly: true } },
      //{ path: '/question-manager/question-detail', name: 'questionDetail', component: () => import('@/views/question-manager/question-detail/detail.vue'), meta: { navOnly: true } },
      // 教辅
      //{ path: '/teaching-manager/teaching-edit', name: 'teachingOperation', component: () => import('@/views/teaching-manager/edit-teaching/index.vue'), meta: { navOnly: true, isKeepAlive: true } },
      //{ path: '/teaching-manager/teaching-detail', name: 'teachingDetail', component: () => import('@/views/teaching-manager/edit-teaching/index.vue'), meta: { navOnly: true, isKeepAlive: true, detail: true } },
      //{ path: '/teaching-manager/question-edit', name: 't_questionOperation', component: () => import('@/views/teaching-manager/edit-question/index.vue'), meta: { navOnly: true, isKeepAlive: true } },
      //{ path: '/teaching-manager/question-link', name: 't_questionLink', component: () => import('@/views/teaching-manager/link-question/index.vue'), meta: { navOnly: true, isKeepAlive: true } },
      //{ path: '/teaching-manager/question-detail', name: 't_questionDetail', component: () => import('@/views/teaching-manager/edit-teaching/components/t-question-detail/index.vue'), meta: { navOnly: true, isKeepAlive: true } },
      // 教辅审核
      //{ path: '/teaching-Audit/teaching-info', name: 'teachingAudit', component: () => import('@/views/teaching-audit/edit-teaching/index.vue'), meta: { navOnly: true, isKeepAlive: true } },
      //{ path: '/school', name: 'statistics_school', component: () => import('@/views/school.vue'), meta: { navOnly: true, isKeepAlive: true } },
      //{ path: '/class', name: 'statistics_class', component: () => import('@/views/class.vue'), meta: { navOnly: true, isKeepAlive: true } },
    ],
  },
  {
    path: "/correction1",
    name: "correction1",
    component: () => import("@/views/correction/online_homework/index.vue"),
    meta: {
      title: "批改",
      icon: "",
      breadcrumb: true,
    },
  },
  {
    path: "/correction3",
    name: "correction3",
    component: () => import("@/views/correction/smart_homework/index.vue"),
    meta: {
      title: "批改",
      icon: "",
      breadcrumb: true,
    },
  },
];

function setKeepAlive(route) {
  const keepAliveViews: Array<string> = ["信息管理"];

  if (route.children && route.children.length) {
    route.children.forEach((item) => {
      setKeepAlive(item);
    });
  } else {
    //根据名字来添加
    if (keepAliveViews.includes(route.name)) {
      route.meta.isKeepAlive = true;
    }
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 异步的路由信息， 同时，左侧菜单也会根据这个来显示
let asyncRouter;

const whiteList = [""];

function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter, "");
  for (let item of asyncRouter) {
    router.addRoute(item);
  }
  next({ ...to, replace: true });
}

function filterAsyncRouter(routes, parent) {
  return routes.filter((route) => {
    const component = route.component;
    if (component) {
      if (route.component === "Layout") {
        if (route.children && route.children.length > 0 && parent) {
          route.component = RouterWrap;
        } else {
          route.component = Layout;
        }
      } else if (typeof component === "string") {
        route.component = getView(component);
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, route);
      }
      return true;
    }
  });
}

function getView(path) {
  // 首先把你需要动态路由的组件地址全部获取
  let modules = import.meta.glob("../**/*.vue");
  // 然后动态路由的时候这样来取
  return modules[`../views/${path}.vue`];
}

router.beforeEach((to, from, next) => {
  const accountState = accountStore();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    const token = accountState.token;
    if (token) {
      if (!asyncRouter) {
        const params = {
          group: "Q-BANK",
        };
        // loginApi.getRouter(params).then((res: any) => {
        // console.log("loginApi.getRouter", res); //去掉后端加载的路由
       var res = [];
        res.push({
          sortValue: 6,
          children: [
            {
              sortValue: 0,
              path: "/index",
              name: "index",
              component: "index",
              meta: {
                title: "首页",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/school",
              name: "statistics_school",
              component: "/school",
              meta: {
                title: "学校详情",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/indexMore",
              name: "indexMore",
              component: "indexMore",
              meta: {
                title: "查看更多详情",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/learningSituation",
              name: "learningSituation",
              component: "learningSituation",
              meta: {
                title: "学情",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/learningSituation/modifyTheCorrectionResult",
              name: "modifyTheCorrectionResult",
              component: "learningSituation/modifyTheCorrectionResult",
              meta: {
                title: "学生详情",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/learningSituation/RevisedAnalysisDetail",
              name: "revisedAnalysisDetail",
              component: "learningSituation/RevisedAnalysisDetail",
              meta: {
                title: "订正详情",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            //assignment_homework checkHomework classErrorQuestionBank studyReport myClass  classSetting
            {
              sortValue: 1,
              path: "/assignmentHomework",
              name: "assignmentHomework",
              component: "assignmentHomework",
              meta: {
                title: "布置作业",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/assignmentHomeworkList",
              name: "assignmentHomeworkList",
              component: "assignmentHomeworkList",
              meta: {
                title: "布置作业记录",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },

            {
              sortValue: 1,
              path: "/assignmentHomeworkEdit",
              name: "assignmentHomeworkEdit",
              component: "assignmentHomeworkEdit",
              meta: {
                title: "布置作业修改",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/assignmentHomeworkTeachAssistantManagement",
              name: "assignmentHomeworkTeachAssistantManagement",
              component: "assignmentHomework/teachAssistantManagement",
              meta: {
                title: "教辅设置",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/checkHomework",
              name: "checkHomework",
              component: "checkHomework",
              meta: {
                title: "检查作业",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/classErrorQuestionBank",
              name: "classErrorQuestionBank",
              component: "classErrorQuestionBank",
              meta: {
                title: "班级错题库",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/classErrorQuestionBanks",
              name: "classErrorQuestionBanks",
              component: "classErrorQuestionBanks",
              meta: {
                title: "班级错题库",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },

            {
              sortValue: 1,
              path: "/studyReport",
              name: "studyReport",
              component: "studyReport",
              meta: {
                title: "学情报告",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
              // children: [
              //   {
              //     sortValue: 2,
              //     path: "/noDetail",
              //     name: "noDetail",
              //     component: "noDetail",
              //     meta: {
              //       title: "错题详情",
              //       icon: "",
              //       breadcrumb: true,
              //     },
              //     hidden: false,
              //     alwaysShow: false,
              //   },
              // ],
            },
            {
              sortValue: 1,
              path: "/noDetail",
              name: "noDetail",
              component: "noDetail",
              meta: {
                title: "错题详情",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              sortValue: 1,
              path: "/studentDetail",
              name: "studentDetail",
              component: "studentDetail",
              meta: {
                title: "学生详情",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },

            {
              sortValue: 1,
              path: "/myClass",
              name: "myClass",
              component: "myClass",
              meta: {
                title: "我的班级",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
            {
              path: "/correctionIndex",
              name: "correctionIndex",
              component: () => import("@/views/correctionIndex.vue"),
              meta: {
                title: "批改",
              },
            },
            {
              sortValue: 1,
              path: "/classSetting",
              name: "classSetting",
              component: "classSetting",
              meta: {
                title: "班级设置",
                icon: "",
                breadcrumb: true,
              },
              hidden: false,
              alwaysShow: false,
            },
          ],
          path: "/index",
          name: "首页",
          component: "Layout",
          meta: {
            title: "首页",
            icon: "",
            breadcrumb: true,
          },
          hidden: false,
          alwaysShow: true,
        });

        setKeepAlive(res[0]);

        if (res[0].name === "题库") {
          asyncRouter = res[0].children;
          asyncRouter.forEach((item) => {
            item.component = "Layout";
          });
        } else {
          asyncRouter = res;
        }
        //动态添加缓存

        //其他页面重定向
        router.addRoute({
          path: "/:pathMatch(.*)*",
          redirect: asyncRouter[0].children[0].path,
        });

        if (!(asyncRouter && asyncRouter.length > 0)) {
          asyncRouter = [];
        }

        // console.log(JSON.parse(JSON.stringify(asyncRouter)));
        accountState.routes = asyncRouter;
        go(to, next);
        // });
      } else {
        accountState.routes = asyncRouter;
        next();
      }
    } else {
      asyncRouter = null;
      if (to.path === "/login") {
        next();
      } else {
        accountState.RESET_STATE();
        next("/login");
      }
    }
  }
});

export default router;
