import http from "./config";

const base = {
  //查询所有试题类型，可以用学科过滤
  getQuestionType(params) {
    return http.get(`/api/paper/paper/papers/questions/types`, { params });
  },
};

export default base;
