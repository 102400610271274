import http from "./config"

export default {
  paper_sheet(params) {
    return http.get(`/api/paper/taskMission/paper/sheet`,{params})
  },
  paper_sheet_paperSheetId_section(params) {
    return http.get(`api/paper/taskMission/paper/sheet/${params.paperSheetId}/section`,{params})
  },
  page(param) {
    return http.post(`api/paper/taskMission/page`,param)
  },
  teacher_batch_issue_mission(param) {
    return http.post(`api/paper/taskMission/teacher/batch/issue/mission`,param)
  },
  paper_sheet_section_paperSheetSectionId_detail(param) {
    return http.get(`api/paper/taskMission/paper/sheet/section/${param.paperSheetSectionId}/detail`,param)
  },
  taskMissionInfo_id(params) {
    return http.get(`api/paper/taskMission/taskMissionInfo/${params.id}`,{params})
  },
  teacher_mission(params) {
    return http.put(`api/paper/taskMission/teacher/mission`,params)
  },
  id_del(params) {
    return http.delete(`/api/paper/taskMission/${params.id}`,params)
  },
  /////api/paper/taskMission/teacher/mission

}
