import http from "./config"

const paperTeacherClassDetails = {
  classList_teacherId(param) {
    return http.get(`/api/paper/teacherClassDetails/classList/${param.teacherId}`)
  },
  classDetails(param) {
    return http.post(`/api/paper/teacherClassDetails/classDetails`,param)
  },
  recentlyHomework(param) {
    return http.post(`/api/paper/teacherClassDetails/recentlyHomework`,param)
  },
  remove_classStudent_studentId(param) {
    return http.delete(`/api/paper/teacherClassDetails/remove/classStudent/${param.studentId}`)
  }
}





export default paperTeacherClassDetails