import { defineStore } from 'pinia'
import paperTeacherClassDetailsApi from "@/api/paperTeacherClassDetails";

type paperTeacherClassDetails = {
  token: string
  refreshToken: string
  userId: string
  name: string
  avatar: string
  expire: number
  routes: Array<object>,
  list:Array<object>,
  currentItem:object
}

export default defineStore({
  id: 'paperTeacherClassDetails',
  state: (): paperTeacherClassDetails => ({
    refreshToken: "",
    token: '',
    name: '',
    avatar: '',
    userId: '',
    expire: 0,
    routes: [],
    list:[],
    currentItem:{}
  }),
  actions: {
    async apiPaperTeacherClassDetailsClassListTeacherId (params) {
      return paperTeacherClassDetailsApi.classList_teacherId(params).then(resp=>{
        console.log('走这里',this.list[0]);
        
        this.list=resp;
        if (this.currentItem.id == null) {
          this.currentItem=this.list[0]
        }
        
      })
    },
    async RESET_STATE() {
      this.$reset()
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'paperTeacherClassDetails',
        storage: localStorage,
        paths: ['token', 'refreshToken', "avatar", "name", "userId", "expire","list","currentItem"]
      }
    ]
  }
})