import http from "./config"

const paperMissions = {
  books_classes_klassId_sheets(params) {
    return http.get(`/api/paper/missions/books/classes/${params.klassId}/sheets`,{params})
  },
  //api/paper/missions/teachers/subjects
  teachers_subjects(params) {
    return http.get(`/api/paper/missions/teachers/subjects`)
  },
  ///api/paper/missions/teachers/{teacherId}/subjects
  teachers_teacherId_subjects(params) {
    return http.get(`/api/paper/missions/teachers/${params.teacherId}/subjects`)
  },
  id_teachers_missions_totals(params) {
    return http.get(`/api/paper/missions/${params.id}/teachers/missions/totals`)
  },
  teachers_missions_wv2(params) {
    return http.get(`/api/paper/missions/teachers/missions/wv2`,{params})
  },
  classes_klassId_subjects(params) {
    return http.get(`/api/paper/missions/classes/${params.klassId}/teachers/subjects`)
  },
  books_classes_klassId_students_sheets(params) {
    return http.get(`api/paper/missions/books/classes/${params.klassId}/students/sheets`,{params})
  },
  students_missions_studentMissionId_questions(params) {
    return http.get(`/api/paper/missions/students/missions/${params.studentMissionId}/questions`,{params})
  },
  students_missions_questions_answers_flags(params) {
    return http.put(`api/paper/missions/students/missions/questions/answers/flags`,params)
  },
  id_teachers_missions(params) {
    return http.get(`/api/paper/missions/${params.id}/teachers/missions`,params)
  },
  teachers_missions_wv2_totals(params) {
    return http.get(`/api/paper/missions/teachers/missions/wv2/totals`,{params})
  },
  id_sheets_pages(params) {
    return http.get(`/api/paper/missions/${params.id}/sheets/pages`,{params})
  },
  klass_id_users_labels(params) {
    return http.get(`/api/paper/missions/klass/${params.id}/users/labels`,{params})
  },
  id_questions(params) {
    return http.get(`/api/paper/missions/${params.id}/questions`,{params})
  },
  students_missions_questions_flags(params) {
    return http.put(`/api/paper/missions/students/missions/questions/flags`,params)
  },
  
  /////api/paper/missions/{id}/questions
}





export default paperMissions