import http from "./config"

const audit = {
  //试题审核-分页列表
  reviewQuestionPage(data) {
    return http.post(`/api/paper/reviewQuestion/errorCorrection/page`, data)
  },
  //试题纠错-错误类型
  getErrorCorrectionType() {
    return http.get(`/api/paper/questionErrorCorrection/getErrorCorrectionType`)
  },
  //知识点-分页列表
  reviewKnowledgePage(data) {
    return http.post(`/api/paper/reviewKnowledge/page`, data)
  },
  //查询教辅列表，教辅审核模块用
  getPapers(params) {
    return http.get(`/api/paper/paper/papers/audits`, params)
  },
  //试题审核-分页列表
  questionPage(data) {
    return http.post(`/api/paper/reviewQuestion/question/page`, data)
  },
  //纠错审核-分页列表
  questionErrorCorrection(data) {
    return http.post(`/api/paper/questionErrorCorrection/page`, data)
  },
//纠错审核-无需解决
ignore(data){
return http.put(`/api/paper/questionErrorCorrection/approved/ignore`,data)
},
  //知识点-单题查询
  knowledgeDetail(id) {
    return http.get(`/api/paper/reviewKnowledge/${id}`)
  },
  //试题详情-单题查询
  questionDetaill(id) {
    return http.get(`/api/paper/question/${id}`)
  },
  //试题纠错详情-单题查询
  questionErrorCorrectionDetaill(id) {
    return http.get(`/api/paper/questionErrorCorrection/${id}`)
  },
  //审核-单题查询
  reviewQuestionDetaill(id) {
    return http.get(`/api/paper/reviewQuestion/${id}`)
  },
  //知识点审核通过
  knowledgeApproved(data) {
    return http.put(`/api/paper/reviewKnowledge/approved`, data)
  },
  //知识点驳回
  knowledgeRejected(data) {
    return http.put(`/api/paper/reviewKnowledge/rejected`, data)
  },
  //试题审核通过
  reviewQuestionApproved(data) {
    return http.put(`/api/paper/reviewQuestion/approved`, data)
  },
  //试题驳回
  reviewQuestionRejected(data) {
    return http.put(`/api/paper/reviewQuestion/rejected`, data)
  },
  //试题纠错审核通过-修改试题原题
  questionErrorApprovedModify(data) {
    return http.put(`/api/paper/questionErrorCorrection/approved/modify`, data)
  },
  // 个性化审核-分页列表
personalizedPage(data){
  return http.post(`/api/paper/questionErrorCorrection/page`, data)
}
}


export default audit