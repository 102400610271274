<script setup>
import { ArrowRight } from "@element-plus/icons-vue";
import { $local } from "@/utils/localStorage"
const router = useRouter();
const route = useRoute();
const tabs = ref([]);

const tabList = ref([])
const NetTabList = [
  {

    name: 'stundents',
    path: '/stundents',
    childList: [
      {
        name: "stundentIndexs",
        path: "/stundentIndexs"
      },
      {
        name: 'stundentTwos',
        path: '/stundentTwos',
        childList: [
          {
            name: "sda",
            path: '/sda'
          }
        ]
      }
    ]
  }

]

function getparentlist(code, tree) {
  let arr = [] //要返回的数组
  for (let i = 0; i < tree.length; i++) {
    let item = tree[i]
    arr = []
    arr.push(item.name) //保存当前节点id
    console.log(arr,);
    if (code == item.name) { //判断当前id是否是默认id
      return arr //是则退出循环、返回数据
    } else { //否则进入下面判断，判断当前节点是否有子节点数据
      if (item.childList && item.childList.length > 0) {
        //合并子节点返回的数据
        arr = arr.concat(getparentlist(code, item.childList))
        console.log(arr,'我');
        if (arr.includes(code)) { //如果当前数据中已包含默认节点，则退出循环、返回数据
          return arr
        }
      }
    }
  }
}


/** 
 * 查找符合条件的对象的整条生命线
 * @params { string } key 需要找到生命线的name值
 * @params { array } tree 数据源
 * @returns { array } 返回处理后的tree
 */
 const findTreeLifeLine = (key, tree) => {
  const result = [...tree]
  for (let index = 0; index < result.length; index++) {
    let { name, childList } = result[index]

    if (name !== key && (!childList || !childList.length)) {
      result.splice(index, 1)
      index--
    } else if (childList && childList.length) {
      result[index].childList = findTreeLifeLine(key, childList)
    }
  }
  return result
}

/** 
 * 扁平化数组 写死了child字段就是 childList
 * @params { array } tree 数据源
 * @returns { array } 返回处理后的数组
 */
const flatList = tree => {
  const result = []
  tree.forEach(({ name, path, childList }) => {
    result.push({ name, path })
    if (childList && childList.length) {
      result.push(...flatList(childList))
    }
  })
  return result
}

const flatAndFindLifeLine = (key, tree) => {
  return flatList(findTreeLifeLine(key, tree))
}


console.log(flatAndFindLifeLine('sda',NetTabList), '我想要的')

//利用route.matched可以得到单个匹配的路径
//路径会发生改变，所以要用watch进行监听

const getBreadCom = () => {
  let mached = route.matched.filter((item) => item.meta.title);
  $local.set('routePath', route.path)
  if (route.path == "/") return;
  if (mached[1].name == "index") {
    mached.splice(1, 1);
    tabList.value = []
  }

  if (mached[1]) {
    mached[1].type = route.query
    tabList.value.push(mached[1])
  }
  // 第一个路径如果不是首页，需要在匹配路径前面拼接一个首页
  console.log(tabList.value, '这是顺序');
  const first = mached[0];
  if (first.path != "/index") {
    mached = [{ path: "/index", meta: { title: "首页" } }].concat(tabList.value);
  } else {
    mached = mached.concat(tabList.value)
    mached.reverse()
    const map = new Map()
    const list = mached.filter(key => !map.has(key.path) && map.set(key.path, 1))
    mached = list.reverse()
  }
  //设置面包屑的数据
  tabs.value = mached;
};

getBreadCom();

function goRouter(e) {
  console.log(e);
  if (e.name == '首页') {
    e.name = 'index'
  }
  router.push({
    name: e.name,
    query: e.type
  })
  // console.log(e,'这是');
  // if (e.type) {
  //   router.push({
  //     path:e.path,
  //     query:e.type
  //   })
  // }else{
  //   router.push({
  //     name:e.name
  //   })
  // }
}

// 监听路径的改变,如果发生变化了，执行方法
watch(
  () => route.path,
  () => getBreadCom()
);
</script>

<template>
  <div class="tags_view">
    <el-breadcrumb :separator-icon="ArrowRight">

      <el-breadcrumb-item v-for="item in tabs">
        <div @click="goRouter(item)">{{ item.meta.title }}</div>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<style lang="scss" scoped>
.tags_view {
  line-height: 48px;
  height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(140, 140, 140, 1);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  padding: 0 129px;
  margin: 21px 0;
}
</style>
