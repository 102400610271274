<script setup>
import { CaretBottom } from "@element-plus/icons-vue";
import paperTeacherClassDetailsApi from "@/api/paperTeacherClassDetails";
import accountStore from "@/stores/accountStore";
import paperTeacherClassDetailsStore from "@/stores/paperTeacherClassDetailsStore";
import paperMissionsStore from "@/stores/paperMissionsStore";
import { $local } from "@/utils/localStorage";
import { useRouter } from "vue-router";
import { getTimeStare } from "@/utils/getTimeState";

const imagePath = `${import.meta.env.VITE_APP_CDN_URL}/question-bank/images`;

const accountState = accountStore();
const paperTeacherClassDetailsState = paperTeacherClassDetailsStore();
const paperMissionsState = paperMissionsStore();

const loginPath = ref();

const timeValue = ref("");
const paperTeacherClassDetailsClassList = ref([]);

const router = useRouter();
// 监听当前路由
watch(
  () => router.currentRoute.value,
  (newValue) => {
    loginPath.value = newValue.name;
  },
  { immediate: true }
);

const handleClick = (params) => {
  console.log(
    "handleClick",
    params,
    paperTeacherClassDetailsState.currentItem.id
  );
  paperTeacherClassDetailsState.currentItem = params;
  // eslint-disable-next-line no-alert
  router.go(0);
};

const changeLogout = () => {
  accountState.RESET_STATE();
  paperMissionsState.RESET_STATE();
  paperTeacherClassDetailsState.RESET_STATE();
  router.replace({ path: "/login" });
};

function clearHandler() {
  window.localStorage.clear();
  router.replace({ path: "/login" });
}

function newPaht() {
  console.log(222);
}

onMounted(() => {
  timeValue.value = getTimeStare();
});
</script>

<template>
  <div class="navBarz" v-if="loginPath == '/index' || loginPath == 'index'">
    <div class="navbarz-top">
      <img class="logo" src="@/assets/imgs/logo-txt.png" />
      <div style="margin-left: auto"></div>

      <div class="subject-name">
        {{ paperMissionsState.currentSubjectItem.name }}
      </div>
      <div style="margin-top: 10px; margin-right: 100px"></div>
      <img class="avatar_icon" :src="`${imagePath}/avatar_icon.png`" />
      <div class="name">
        {{ paperTeacherClassDetailsState.currentItem.name }}&nbsp;&nbsp;
        {{ accountState.name }}老师，你好
      </div>
      <!-- <div style="margin-top: 10px;margin-right: 100px;">
          5555
        <el-dropdown trigger="click" >
                        <span class="el-dropdown-link">
                          <el-icon><CaretBottom /></el-icon>
                        </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item v-for="subjectItem in paperMissionsState.subjectList">{{subjectItem.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                      </el-dropdown>
      </div> -->
      <!-- <img class="sign_out" @click="changeLogout" :src="`${imagePath}/sign_out.png`" /> -->

      <div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <el-icon>
              <CaretBottom />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="paperTeacherClassDetailsClassItem in paperTeacherClassDetailsState.list"
                @click="handleClick(paperTeacherClassDetailsClassItem)"
                >{{ paperTeacherClassDetailsClassItem.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="clearButton" @click="clearHandler">退出登录</div>
    </div>
    <div class="navbarz-bottom">
      <div>
        {{ timeValue }}, {{ paperTeacherClassDetailsState.currentItem.name
        }}{{ paperMissionsState.currentSubjectItem.name }}老师
      </div>
      <div>欢迎使用安教智慧作业老师工作台</div>
    </div>
  </div>
  <div class="navbar" v-else>
    <img class="logo" src="@/assets/imgs/logo-txt.png" />
    <div style="margin-left: auto"></div>
    <div class="subject-name">
      {{ paperMissionsState.currentSubjectItem.name }}
    </div>
    <!-- <div style="margin-top: 10px;margin-right: 100px;">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-icon>
            <CaretBottom />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="subjectItem in paperMissionsState.subjectList">{{ subjectItem.name }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div> -->
    <img class="avatar_icon" :src="`${imagePath}/avatar_icon.png`" />
    <div class="name">
      {{ paperTeacherClassDetailsState.currentItem.name }}&nbsp;&nbsp;
      {{ accountState.name }}老师，你好
    </div>
    <!-- <img class="sign_out" @click="changeLogout" :src="`${imagePath}/sign_out.png`" /> -->

    <div>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-icon>
            <CaretBottom />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="paperTeacherClassDetailsClassItem in paperTeacherClassDetailsState.list"
              @click="handleClick(paperTeacherClassDetailsClassItem)"
              >{{ paperTeacherClassDetailsClassItem.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="clearButton" @click="clearHandler">退出登录</div>
  </div>
</template>
<style lang="scss" scoped>
.navbar {
  height: 96px;
  background: #fff;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  color: #000;
  padding: 0 129px;
  box-sizing: border-box;
  .clearButton {
    font-size: 15px;
  }
  .subject-name {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #333333;
    letter-spacing: 1px;
    white-space: nowrap;
  }

  .logo {
    width: 265px;
    height: 33px;
  }

  .name {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    border-right: 1px solid #fff;
    padding-right: 16px;
  }

  .sign_out {
    width: 16px;
    height: 16px;
    margin: 0 10px;
    padding: 12px;
  }

  .avatar_icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}

.navBarz {
  width: 100%;
  height: 433px;
  background: url(https://qcjy-sos-1253528142.cos.ap-shanghai.myqcloud.com/pc/indexBg.png)
    no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;

  .navbarz-top {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 32px;
    color: #000;
    padding: 35px 129px;
    box-sizing: border-box;

    .subject-name {
      font-size: 17px;
      font-family: MicrosoftYaHei;
      color: #333333;
      letter-spacing: 1px;
      white-space: nowrap;
    }

    .logo {
      width: 384px;
      height: 48px;
    }

    .name {
      font-size: 17px;
      font-weight: 400;
      color: #000;
      padding-right: 16px;
    }

    .sign_out {
      width: 16px;
      height: 16px;
      margin: 0 10px;
      padding: 12px;
    }

    .avatar_icon {
      width: 37px;
      height: 37px;
      margin-right: 10px;
    }
  }

  .navbarz-bottom {
    padding: 36px 32px;
    color: #000;
    padding: 35px 129px;
    box-sizing: border-box;
    font-size: 24px;
    font-family: MicrosoftYaHei;
    color: #333333;
    font-weight: 600;

    div:nth-child(2) {
      font-size: 18px;
      color: #575151;
      letter-spacing: 1px;
      margin-top: 30px;
    }
  }

  .clearButton {
    font-size: 17px;
  }
}
</style>
