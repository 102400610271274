import { defineStore } from 'pinia'
import auditApi from "@/api/audit";

type options = {
  errorCorrectionType: object
}
export default defineStore({
  id: 'options',
  state: (): options => ({
    errorCorrectionType: {},//纠错错误类型
  }),
  actions: {
    async getErrorCorrectionType() {
      return auditApi.getErrorCorrectionType().then((res: any) => {
        for (const item of res) {
          this.errorCorrectionType[item.code] = {
            label: item.desc
          }
        }
      });
    },
    async RESET_STATE() {
      this.$reset()
    }
  },
  persist: {
    enabled: true,
  }
})