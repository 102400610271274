<script setup >
import attributeStore from "@/stores/attributeStore";
import optionsStore from "@/stores/optionsStore";
import navbar from "./components/navbar";
import sidebar from "./components/sidebar";
import tagsView from "./components/tagsView";
const attributeState = attributeStore();
const optionsState = optionsStore();
const route = useRoute();

const state = ref('')

const key = computed(() => {
  return route.path;
});
const navOnly = computed(() => route.meta?.navOnly)

const router = useRouter()
// 监听当前路由
watch(
  () => router.currentRoute.value,
  (newValue) => {
    state.value = newValue.name
  },
  { immediate: true }
)


onMounted(() => {
  // attributeState.getTopicType();
  //optionsState.getErrorCorrectionType();
});
</script>
<template>
  <div class="layout">
    <navbar  />
    <div class="content" :class="state=='index'||state=='/index'?'contentPd':''" >
      <!--<sidebar v-if="!navOnly" />-->
      <div class="app_main">
        <tagsView v-if="!navOnly" />
        <div class="main">
          <router-view v-slot="{Component, route}">
            <keep-alive>
              <component :is="Component" :key="route.name" v-if="route.meta.isKeepAlive"/>
            </keep-alive>
            <component :is="Component" :key="route.name" v-if="!route.meta.isKeepAlive" />
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: #F7F7F7;
  .content {
    display: flex;
    flex: 1;
    height: 0;
    
    .app_main {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 0;
    

      .main {
        flex: 1;   
        width: 100%;
        overflow-y: scroll;

      }
    }
  }
  .contentPd{
    margin-top: -200px;
  }
}
</style>